<template>

	<latte-overlay :name="uniqueId">

		<div class="panel" style="width: 480px" :class="{'is-loading': isLoading}">
			<div class="panel-header">
				<span class="panel-title">Add Your Tip</span>
				<button class="btn btn-icon btn-text btn-dark ml-auto" @click="close"><i class="mdi mdi-window-close"></i></button>
			</div>
			<div class="panel-body">

				<div class="form-group">
					<input type="text" placeholder="Your title goes here" class="form-control" v-model="fields.title"/>
				</div>

				<div class="form-group">
					<textarea type="text" placeholder="Add an interesting fact here" class="form-control" rows="5" v-model="fields.message"></textarea>
				</div>

				<div class="form-group">
					<input type="email" placeholder="Your e-mailaddress" class="form-control" v-model="fields.email"/>
				</div>

			</div>
			<div class="panel-footer justify-content-end">
				<button class="btn btn-text btn-dark" @click="close"><span>Cancel</span></button>
				<button class="btn btn-contained btn-primary" @click="submit" :disabled="!canSubmit"><i class="mdi mdi-check-circle-outline"></i><span>Submit</span></button>
			</div>
			<span class="spinner spinner-primary"></span>
		</div>

	</latte-overlay>

</template>

<script>

	export default {

		name: "Compose",

		data()
		{
			return {
				fields: {
					email: "",
					message: "",
					title: ""
				},
				isLoading: false,
				uniqueId: this.$latte.api.id()
			};
		},

		computed: {

			canSubmit()
			{
				if (this.fields.title.trim().length < 3)
					return false;

				if (this.fields.message.trim().length < 10)
					return false;

				if (this.fields.email.trim().length < 3)
					return false;

				if (this.fields.email.indexOf("@") === -1 || this.fields.email.indexOf(".") === -1)
					return false;

				// Yay!
				return true;
			}

		},

		methods: {

			close()
			{
				this.reset();
				this.$latte.overlays.close(this.uniqueId);
			},

			open()
			{
				this.reset();
				this.$latte.overlays.open(this.uniqueId);
			},

			reset()
			{
				this.fields.email = "";
				this.fields.message = "";
				this.fields.title = "";
			},

			async submit()
			{
				this.isLoading = true;

				const tip = {
					email: this.fields.email,
					message: this.fields.message,
					title: this.fields.title
				};

				await new Promise(resolve => setTimeout(resolve, 660));

				this.$emit("tip", tip);

				this.isLoading = false;
				this.close();
			}

		}

	}

</script>
