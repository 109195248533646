<template>

	<div class="content app-view" id="view-tips">

		<transition-group name="tips-list">

			<template v-for="tip of tips">
				<Tip ref="tips" :key="tip.id" :tip="tip"></Tip>
			</template>

		</transition-group>

		<latte-ripple as="button" class="btn btn-contained btn-fab btn-primary" @click="openCompose"><i class="mdi mdi-plus"></i></latte-ripple>

		<Compose @tip="addTip" ref="compose"/>

	</div>

</template>

<script>

	import Tip from "../component/Tip";
	import Compose from "../component/Compose";

	export default {

		components: {
			Compose,
			Tip
		},

		created()
		{
			this.addTip({
				email: "",
				title: "Bring your own bottle",
				message: "Plastic bottles are among the top 10 disposable products that ends up in the sea."
			});

			this.addTip({
				email: "",
				title: "Skip the straw",
				message: "Plastic straws can take up to 200 years to decompose, way beyond our lifespans!"
			});

			this.addTip({
				email: "",
				title: "Use reusable bags",
				message: "Annually approximately 500 billion plastic bags are used worldwide."
			});

			this.addTip({
				email: "",
				title: "Give up gum",
				message: "50 percent of the plastic we use, we use just once and throw away."
			});

			this.addTip({
				email: "",
				title: "Stop using plastic cutlery",
				message: "Virtually every piece of plastic that was ever made still exists in some shape or form."
			});

			this.addTip({
				email: "",
				title: "Cardboard over plastic",
				message: "Enough plastic is thrown away each year to circle the earth four times."
			});

			this.addTip({
				email: "",
				title: "Avoid microbeats",
				message: "Plastic accounts for around 10 percent of the total waste we generate."
			});

			this.tips.reverse();
		},

		mounted()
		{
			if (this.tips.length > 0)
				this.$refs.tips[0].open();
		},

		data()
		{
			return {
				tips: []
			};
		},

		methods: {

			addTip(tip)
			{
				tip.id = this.$latte.api.id();

				this.tips.unshift(tip);
			},

			openCompose()
			{
				this.$refs.compose.open();
			}

		}

	}

</script>

<style lang="scss" scoped>

	div#view-tips
	{
		background: url(../../public/image/default.jpg) no-repeat fixed center center / cover;
	}

	.btn-fab
	{
		--ripple-center: true;

		position: fixed;
		right: 30px;
		bottom: 30px;
		z-index: 10;
	}

</style>

<style lang="scss">

	.tips-list-enter-active,
	.tips-list-leave-active,
	.tips-list-move
	{
		transition: all 240ms var(--ease-swift-out);
	}

	.tips-list-enter
	{
		opacity: 0;
		transform: translate3d(0, -48px, 0);
	}

	.tips-list-leave-to
	{
		opacity: 0;
	}

</style>
