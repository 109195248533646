<template>

	<div class="panel">
		<latte-ripple as="a" @click="open" class="panel-header border-bottom-0">
			<span class="panel-title mx-auto">{{ tip.title }}</span>
		</latte-ripple>
		<div class="panel-body pt-0 text-center">
			{{ tip.message }}
		</div>
	</div>

</template>

<script>

	import { Latte } from "@bybas/latte-ui";

	export default {

		name: "Tip",

		props: {

			tip: {
				default: null,
				required: true,
				type: Object | null
			}

		},

		data()
		{
			return {
				ignore: false,
				isOpen: false
			};
		},

		mounted()
		{
			Latte.actions.on("lastic:tip-open", () =>
			{
				if (this.ignore)
					return;

				this.close();
			});
		},

		methods: {

			close()
			{
				this.isOpen = false;
			},

			open()
			{
				this.ignore = true;

				Latte.actions.dispatch("lastic:tip-open");

				Latte.dom.raf(() =>
				{
					this.ignore = false;
					this.isOpen = true;
				});
			}

		},

		watch: {

			isOpen()
			{
				const body = this.$el.querySelector("div.panel-body");

				if (this.isOpen)
				{
					body.style.setProperty("height", "auto");
					body.style.setProperty("padding", null);

					const style = getComputedStyle(body);
					const height = parseInt(style.height) + 30;

					body.style.setProperty("height", null);
					body.style.setProperty("padding", null);
					body.classList.add("is-transitionable");

					Latte.dom.raf(() =>
					{
						body.style.setProperty("height", `${height}px`);
						body.style.setProperty("padding", "0 30px 30px 30px");

						Latte.dom.raf(() => body.classList.remove("is-transitionable"), 500);
					});
				}
				else
				{
					body.classList.add("is-transitionable");

					Latte.dom.raf(() =>
					{
						body.style.setProperty("height", null);
						body.style.setProperty("padding", null);

						Latte.dom.raf(() => body.classList.remove("is-transitionable"), 300);
					});
				}
			}

		}

	}

</script>

<style lang="scss" scoped>

	div.panel-body
	{
		height: 0;
		padding: 0;
		overflow: hidden;

		&.is-transitionable
		{
			transition: all 300ms var(--ease-swift-out);
			will-change: height, padding;
		}
	}

</style>
